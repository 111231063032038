import React from 'react'

import ResetPassword from '../templates/resetPassword'

import AuthComponent from '../components/authComponent'
import Layout from '../components/layout'

const ResetPasswordPage = ({
    location
}) => {
    return (
        <AuthComponent> 
            <Layout>
                <ResetPassword/>
            </Layout>
        </AuthComponent>
    )
}

export default ResetPasswordPage